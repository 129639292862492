<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'class-3'"
    :headerClass="'fixed-home'"
    :contentColor="'light'"
    :backButtonClass="'btn-1'"
    :selectLanguage="true"
  >
    <template v-slot:ion-content>
      <div class="wrap">
        <div class="image">
          <img
            src="../../assets/img/notifications-permission_illustration.png"
            alt=""
          />
        </div>

        <h2>{{ lang.allow_draw_page.title }}</h2>
        <p>
          {{ lang.allow_draw_page.description }}
        </p>
        <ion-button class="enable" @click="requestOverlayPermission()">
          <span>{{ lang.allow_draw_page.enable_draw_btn_text }}</span
          ><ion-icon :icon="arrowForward" slot="end"></ion-icon>
        </ion-button>

        <a href="#" class="exit" @click="exit($event)">{{ lang.allow_draw_page.exit_btn_text }}</a>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { arrowForward } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import { Plugins } from "@capacitor/core";
const { NativeHelper /* App */ } = Plugins;

export default {
  inject: ["store"],
  data() {
    return {
      arrowForward,
    };
  },
  components: {
    IonButton,
    IonIcon,
    "base-layout-2": BaseLayout2,
  },

  methods: {
    exit(ev) {
      ev.preventDefault();
      this.store.actions.buttonExited();
      NativeHelper.moveToBackground();
    },

    async requestOverlayPermission() {
      await NativeHelper.requestOverlayPermission();
    },
  },

  async ionViewDidEnter() {
    let self = this;

    self.store.actions.setStatusBarStyleLight();

    this.store.loader(false);

    self.emitter.on("overlay-is-on", () => {
      self.$router.push({
        path: "/spots",
        replace: true,
      });
    });

    self.emitter.on("back-allow-draw", () => {
      NativeHelper.moveToBackground();
    });
  },

  ionViewDidLeave() {
    let self = this;
    self.emitter.off("overlay-is-on");
  },
};
</script>


<style scoped lang="scss">
.page-container {
  border-top-left-radius: 40px;
  background: var(--ion-color-light);
  overflow: auto;
  padding-top: 50px;
}

.wrap {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    padding: 20px;
    position: relative;
    width: 63%;
    max-width: 300px;

    img {
      max-width: unset;
      width: 100%;
      position: relative;
      // left: 24%;
    }
  }

  h2 {
    font-weight: bold;
    text-align: center;
  }

  p {
    text-align: center;
    width: 70vw;
  }
}

ion-button.enable {
  --border-radius: 30px;
  text-transform: unset;
  font-weight: 400;
  font-size: calc(16px + $extra-font-size);
  word-break: break-all;
  min-height: 55px;

  @media (max-width: 320px) {
    font-size: calc(14px + $extra-font-size);
  }
}

.exit {
  display: block;
  margin-top: 20px;
  font-weight: 800;
  color: var(--ion-color-primary);
  text-decoration: none;
}
</style>